﻿.trading-limits {
    .tabs {
        padding-left: 24px;
    }

    .content-section {
        position: relative;
    }

    .description {
        margin-bottom: 16px;
        line-height: 24px;

        .btn {
            vertical-align: top;
        }
    }

    .control {
        .btn:not(:first-child) {
            margin-left: 8px;
        }
    }

    .section {
        margin-bottom: 32px;
        flex: none;
    }

    .form-row {
        margin-bottom: 0;

        &.editing {
            &:not(:last-child) {
                margin-bottom: 24px;
            }
        }

        .form-item {
            display: flex;
            flex-direction: row;

            .form-label {
                flex: 0 0 220px;
                margin-right: 20px;
                line-height: 32px;
                font-size: 14px;
                color: $grey-8;
            }

            .value,
            .form-control {
                flex: 0 0 200px;
                text-align: right;
            }

            .value {
                padding-right: 8px;
                line-height: 30px;
                border: 1px solid transparent;
            }
        }
    }

    .data-list {
        flex: none;
        margin-bottom: 8px;

        & + .total-limits-row {
            padding-left: 24px;
        }

        .data-list-content:after {
            display: none;
        }
    }

    .total-limits-row {
        line-height: 32px;

        .label {
            width: 150px;
        }
    }

    .empty-placeholder {
        margin: 0;
    }
}