﻿$grid-column-width-xxs: 60px;
$grid-column-width-xs: 80px;
$grid-column-width-sm: 100px;
$grid-column-width-md: 125px;
$grid-column-width-md-02: 150px;
$grid-column-width-lg: 180px;
$grid-column-width-lg-02: 200px;
$grid-column-width-xl: 240px;
$grid-column-width-xxl: 280px;
$grid-column-width-xxxl: 500px;

.grid {
    th,
    td {
        &.cell-bidSize,
        &.cell-size,
        &.cell-bid,
        &.cell-offer,
        &.cell-bidDmBps,
        &.cell-offerDmBps,
        &.cell-offerYield,
        &.cell-walYears,
        &.cell-spread,
        &.cell-creditEnhancement,
        &.cell-mvoc,
        &.cell-margin,
        &.cell-originalMargin,
        &.cell-balance,
        &.cell-dm,
        &.cell-oid,
        &.cell-capMargin,
        &.cell-minBidAmount,
        &.cell-principalAmountStep,
        &.cell-redemptionPrice,
        &.cell-redemptionPriceInPercent {
            text-align: right;
        }
    }

    td {
        &.cell-color,
        &.cell-description,
        &.cell-manager {
            text-transform: none;
        }

        &.readonly {
            background-color: $grid-cell-inactive;
            pointer-events: none;
        }
    }
}

.cell-factor,
.cell-equity {
    width: $grid-column-width-xxs;
    min-width: $grid-column-width-xxs;
    max-width: $grid-column-width-xxs;
}

.cell-rating,
.cell-mvoc,
.cell-offerDmBps,
.cell-offer,
.cell-bid,
.cell-creditEnhancement,
.cell-offerYield,
.cell-walYears,
.cell-rating,
.cell-collateralType,
.cell-yrsAtCompany,
.cell-name,
.cell-status,
.cell-redeemed,
.cell-amr,
.cell-nonAmrPeriod {
    width: $grid-column-width-xs;
    min-width: $grid-column-width-xs;
    max-width: $grid-column-width-xs;
}

.cell-currency,
.cell-currencyCode,
.cell-asset,
.cell-firstName,
.cell-company,
.cell-email,
.cell-company,
.cell-companyCode,
.cell-phone,
.cell-role,
.cell-bidDmBps,
.cell-offerDmBps,
.cell-spread,
.cell-riEnd,
.cell-ncEnd,
.cell-margin,
.cell-dm,
.cell-oid,
.cell-nonCallPeriodEnd,
.cell-reinvestmentPeriodEnd,
.cell-type,
.cell-yrsExperience,
.cell-primaryDesk,
.cell-transactionClosingDate,
.cell-transactionType,
.cell-originalMargin,
.cell-originalFloaterIndex,
.cell-floaterIndex,
.cell-originalRatingMoodys,
.cell-originalRatingSnP,
.cell-originalRatingFitch,
.cell-originalRatingKbra,
.cell-originalRatingDbrs,
.cell-ratingMoodys,
.cell-ratingSnP,
.cell-ratingFitch,
.cell-ratingKbra,
.cell-ratingDbrs,
.cell-capMargin,
.cell-minBidAmount,
.cell-principalAmountStep,
.cell-pricingDate,
.cell-nonAmrPeriod {
    width: $grid-column-width-sm;
    min-width: $grid-column-width-sm;
    max-width: $grid-column-width-sm;
}

.cell-pxTalk,
.cell-pxTalks,
.cell-originalFloaterIndex,
.cell-floaterIndex,
.cell-size,
.cell-bidSize,
.cell-classReferenceName,
.cell-balance,
.cell-guidance,
.cell-subscription,
.cell-percent,
.cell-expectedTiming,
.cell-euCompliance,
.cell-anchor,
.cell-dealStage,
.cell-phoneNumber,
.cell-meetingContact,
.cell-officePhoneNumber,
.cell-mobilePhoneNumber,
.cell-cusiP144A,
.cell-isiN144A,
.cell-cusipRegS,
.cell-isinRegS,
.cell-originalBalance,
.cell-balance,
.cell-minimumDenomination,
.cell-pariPassuTranches,
.cell-redemptionPrice,
.cell-settlementDate,
.cell-trancheSupported,
.cell-redemptionPriceInPercent,
.cell-originalCoupon,
.cell-coupon {
    width: $grid-column-width-md;
    min-width: $grid-column-width-md;
    max-width: $grid-column-width-md;
}

.cell-isinCusip,
.cell-submitDate,
.cell-comment,
.cell-location,
.cell-lastName,
.cell-ticker144A,
.cell-tickerRegS,
.cell-commonCodeRegS,
.cell-isinAccdInvCertif,
.cell-type,
.cell-nonAmrPeriodEnd,
.cell-classSupported,
.cell-status,
.cell-cusip144A,
.cell-isin144A {
    width: $grid-column-width-md-02;
    min-width: $grid-column-width-md-02;
    max-width: $grid-column-width-md-02;
}

.cell-ticker,
.cell-arrangerReferenceName,
.cell-linkedIn,
.cell-tickerAccdInvCertif,
.cell-cusipAccdInvCertif,
.cell-isinAccdInvCertif {
    width: $grid-column-width-lg;
    min-width: $grid-column-width-lg;
    max-width: $grid-column-width-lg;
}

.cell-color,
.cell-ioiRatings,
.cell-dealLegalName,
.cell-settlementAccountId,
.cell-fullName {
    width: $grid-column-width-lg-02;
    min-width: $grid-column-width-lg-02;
    max-width: $grid-column-width-lg-02;
}

.cell-manager,
.cell-description,
.cell-disclosureFile,
.cell-title,
.cell-email {
    width: $grid-column-width-xxl;
    min-width: $grid-column-width-xxl;
    max-width: $grid-column-width-xxl;
}

.cell-manager,
.cell-description {
    .cell-item-control {
        position: relative;
        z-index: 35;
        min-height: 32px;
        box-shadow: 0 0 0 1px $grid-borders-focus;
        background-color: $white-1;

        &:not(.form-select) {
            padding: 6px 8px;
        }
    }
}

.cell-deal-tranches {
    width: $grid-column-width-xxxl;
    min-width: $grid-column-width-xxxl;
    max-width: $grid-column-width-xxxl;

    .data-part {
        line-height: 15px;
        display: inline-block;
        margin-right: 30px;
        width: 350px;

        &-text {
            white-space: nowrap;
            margin-bottom: 0;
        }
    }



    .btn-link {
        vertical-align: top;
        margin-top: 6px;
    }
}

.cell-om,
.cell-indenture,
.cell-collateralManagerReferenceName,
.cell-linkedTransactionReferenceAndClass {
    width: $grid-column-width-xl;
    min-width: $grid-column-width-xl;
    max-width: $grid-column-width-xl;

    .cell-item-file {
        width: 100%;
    }
}

.cell-other {
    width: calc(100vw - 999px);
    min-width: calc(100vw - 999px);
    max-width: calc(100vw - 999px);

    .cell-other-cnt {
        display: flex;
        flex-wrap: wrap;
        margin-top: -1px;
        margin-left: -1px;

        .cell-item-file {
            border-top: 1px solid $grid-borders;
            border-left: 1px solid $grid-borders;
            flex: 1 1 33%;
            height: 41px;
            position: relative;
        }
    }

    @media only screen and (max-width: 1280px) {
        width: 283px;
        min-width: 283px;
        max-width: 283px;
    }
}

.address-book {
    .grid {
        td {
            text-transform: none;
        }

        .cell-firstName,
        .cell-lastName {
            width: $grid-column-width-md-02;
            min-width: $grid-column-width-md-02;
            max-width: $grid-column-width-md-02;
        }

        .cell-email {
            width: $grid-column-width-xxl;
            min-width: $grid-column-width-xxl;
            max-width: $grid-column-width-xxl;
        }

        .cell-company {
            width: $grid-column-width-xxl;
            min-width: $grid-column-width-xxl;
            max-width: $grid-column-width-xxl;
        }

        .cell-role {
            width: $grid-column-width-md;
            min-width: $grid-column-width-md;
            max-width: $grid-column-width-md;
        }

        .cell-phone {
            width: $grid-column-width-md-02;
            min-width: $grid-column-width-md-02;
            max-width: $grid-column-width-md-02;
        }

        .form-error {
            min-width: 0;
        }
    }
}

.trade-allocation {
    .grid {
        .cell-no-data {
            &:first-child {
                @include flex-basis(32px);
            }
        }

        tfoot {
            .cell-no-data {
                &:first-child {
                    display: none;
                }

                &.cell-total {
                    @include flex-basis(64px);
                    padding-right: 10px;
                }
            }
        }

        td, th {
            text-transform: none;
            text-align: right;

            &.cell-settlementAccountId {
                text-align: left;
            }
        }

        .cell-amount {
            @include flex-basis($grid-column-width-md-02);
        }

        .cell-percent,
        .cell-proceed {
            @include flex-basis($grid-column-width-md);
        }
    }
}
