.notifications-center {
    .content-section {
        overflow-x: hidden;
    }

    .tabs {
        white-space: nowrap;
        margin: -32px -24px 0;

        .horizontal-slider {
            padding: 0 29px;

            &.slider {
                padding: 0;

                .horizontal-slider-button {
                    display: block;
                }
            }
        }

        .horizontal-slider-button {
            display: none;
        }
    }

    &-tab {
        .status-message {
            margin-bottom: 16px;
        }
    }

    .save-changes {
        min-height: 56px;
        padding: 12px 0;

        .status-message {
            display: inline-flex;
            width: auto;
            margin: 0;
        }

        .btn {
            margin-left: 8px;
        }
    }

    .controls-wrap {
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 16px;
        }

        &-head {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
        }

        &-info {
            color: $grey-8;
            margin-bottom: 0;
        }

        &-title {
            width: 371px;
            margin: 0;

            .btn-link {
                margin-right: 8px;

                .icon-svg {
                    margin-right: 0;
                }
            }
        }

        &-radio {
            display: flex;

            .controls-radio-item {
                width: 80px;
                display: flex;
                align-items: center;

                .info-tooltip {
                    margin-left: 4px;
                    line-height: 16px;

                    .icon-svg {
                        vertical-align: top;
                    }
                }

                .limit-blocker {
                    height: 16px;
                }
            }
        }
    }

    .data-list {
        overflow: visible;
        margin-top: 24px;

        .data-list-content {
            overflow-y: visible;

            &:after {
                display: none;
            }
        }

        .form-error {
            width: 160px;
            height: auto;
            white-space: normal;
        }

        &.data-list-saved-filter {
            .data-list-cell-xxxl:first-child {
                @include flex-basis(339px);
            }
        }
    }

    .status-message,
    .empty-placeholder {
        margin: 16px 0 0;
    }

    .placeholder-row {
        align-items: center;
        height: 32px;
        margin-bottom: 16px;
        padding: 0 24px;
        border-bottom: 1px solid $border-color;

        .restricted-placeholder {
            padding: 0;
        }

        .icon-svg {
            margin-left: -4px;
            margin-right: 2px;
            transform: scale(0.6665);
        }
    }
}