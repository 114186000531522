﻿.blotter-container {
    position: relative;

    .data-list-options {
        overflow: visible;

        .btn-ghost {
            min-width: 120px;
        }

        .affirm-trade-buttons {
            .btn-link {
                margin-left: 16px;

                .icon-svg {
                    margin-right: 0;
                }
            }

            & + .btn {
                margin-left: 16px;
            }
        }

        .limit-blocker {
            display: inline-block;
        }
    }

    .component-collapsible-list {
        .trade-allocation-table-content {
            .container-loader {
                align-items: flex-start;
                padding-left: 84px;
            }
        }

        .component-collapsible-list {
            .data-list-cell-collapse-action {
                & + .data-list-cell {
                    margin-right: -24px;
                }
            }

            .trade-allocation-table-content {
                .container-loader {
                    padding-left: 80px;
                }
            }
        }
    }

    .data-column-sticky-right {
        .trade-allocation-table-content {
            .preloader {
                opacity: 0;
            }
        }
    }
}

.sub-header-blotter {
    .filters-area {
        flex: auto;

        & > .filter-section:nth-last-child(2) {
            margin-right: 130px;
        }
    
        .row-filters-btns {
            flex: none;
            display: flex;
            flex-direction: row;
            margin-left: -122px;
        }
    }
    
}
